import {onCLS, onFCP, onFID, onINP, onLCP, onTTFB} from 'web-vitals';

window.WebVitalize = window.WebVitalize || {};

const API_ENDPOINT = window.WebVitalize['endpoint'] || 'https://ingest.webvitalize.io/api/log';
const PAGE_TYPE = window.WebVitalize['pageType'] || null;

if (typeof window.WebVitalize['enabled'] === 'undefined') {
    switch (window.location.hostname) {
        // 50% example
        case 'pi-horizon-staging-web.ringier.tech':
        case 'ed-horizon-staging-web.ringier.tech':
            window.WebVitalize['enabled'] = Math.random() < 0.5;
            break;

        case 'www.abola.pt':
            window.WebVitalize['enabled'] = Math.random() < 0.2;
            break;

        case 'www.kiskegyed.hu':
            window.WebVitalize['enabled'] = Math.random() < 0.2;
            break;

        case 'www.blic.rs':
            window.WebVitalize['enabled'] = Math.random() < 0.3;
            break;

        case 'www.blick.ch':
            window.WebVitalize['enabled'] = Math.random() < 0.3;
            break;

        case 'www.blikk.hu':
            window.WebVitalize['enabled'] = Math.random() < 0.3;
            break;

        case 'www.sportnieuws.nl':
            window.WebVitalize['enabled'] = Math.random() < 0.3;
            break;

        default:
            window.WebVitalize['enabled'] = true;
            break;
    }
}
const ENABLED = !!window.WebVitalize['enabled'];

const queue = new Set();

const addToQueue = function (metric) {
    if (metric.value !== metric.delta) {
        return;
    }

    queue.add(metric);
    setTimeout(() => sendToAnalytics(), 250);
};

const sendToAnalytics = function () {
    if (queue.size === 0) {
        return;
    }

    const fullUrl = window.location.href;
    const obj = {href: fullUrl};
    queue.forEach(function (metric) {
        obj[metric.name] = metric.value;
    });
    if (
        typeof PAGE_TYPE === 'string'
        && PAGE_TYPE.length
    ) {
        obj['pageType'] = PAGE_TYPE;
    }
    const body = JSON.stringify(obj);
    const headers = {
        'Content-Type': 'application/json',
    };

    (
        navigator.sendBeacon
        && navigator.sendBeacon(API_ENDPOINT, body)
    )
    || fetch(
        API_ENDPOINT,
        {
            body: body,
            headers: headers,
            method: 'POST',
            keepalive: true,
        },
    );

    queue.clear();
};

if (ENABLED) {
    // core
    onCLS(addToQueue);
    onINP(addToQueue);
    onLCP(addToQueue);

    // extra
    onFID(addToQueue);
    onFCP(addToQueue);
    onTTFB(addToQueue);
}
